<template>
  <div class="followAdd">
    <div class="con-item">
      <div class="con-item-title">基本信息</div>
      <el-form
        class="inline-form"
        :rules="dataRules"
        label-width="100px"
        :model="form"
        ref="form"
      >
        <el-form-item label="随访名称" prop="followUpName">
          <el-input
            style="width: 350px"
            placeholder="请输入随访名称"
            v-model.trim="form.followUpName"
          ></el-input>
        </el-form-item>
        <el-form-item label="内容类型" prop="followUpType">
          <el-select
            placeholder="请选择分类"
            v-model="form.followUpType"
            clearable
            style="width: 350px"
          >
            <el-option
              v-for="(item, index) in [
                { name: '宣教', value: 2 },
                { name: '问卷', value: 1 },
              ]"
              :key="index"
              :value="item.value"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-form label-width="100px">
        <el-form-item label="选择内容" prop>
          <div>
            <el-button type="primary" @click="addMain">请选择</el-button>
          </div>
          <el-table
            class="mt20"
            v-if="form.followUpItem.length"
            :data="form.followUpItem"
            style="width: 80%"
          >
            <el-table-column prop="name" label="已选择内容"></el-table-column>
            <el-table-column
              prop="authorityTypeName"
              label="操作"
              align="right"
              header-align="right"
            >
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="del(scope.row)">
                  <img src="~@/assets/img/delete-btn.png" />
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
    </div>
    <el-divider></el-divider>
    <div class="con-item">
      <div class="con-item-title">人员筛选</div>
      <el-form label-width="100px" :model="form" ref="form">
        <div class="form-row">
          <el-form-item label="筛查条件" required>
            <el-radio-group v-model="radio">
              <el-radio :label="1">按条件筛选</el-radio>
              <el-radio :label="2">直接导入</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="加入时间范围" v-if="radio == 1">
            <el-date-picker
              v-model="form.addTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              clearable
              style="width: 350px"
            ></el-date-picker>
          </el-form-item>
        </div>
        <div class="form-row" v-if="radio == 1">
          <el-form-item label="年龄范围">
            <div class="form-row-flex">
              <el-input
                type="number"
                :min="0"
                v-model="form.age1"
                style="width: 155px"
                placeholder="请输入"
              ></el-input>
              <span class="age-range-line"></span>
              <el-input
                type="number"
                :min="0"
                v-model="form.age2"
                style="width: 155px"
                placeholder="请输入"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="选择标签">
            <el-select
              placeholder="请选择标签"
              v-model="form.labelId"
              clearable
              style="width: 350px"
            >
              <el-option
                v-for="(item, index) in tagList"
                :key="index"
                :value="item.id"
                :label="item.labelName"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-row" v-if="radio == 1">
          <el-form-item label="选择分组">
            <el-select
              placeholder="请选择分组"
              v-model="form.groupingId"
              clearable
              style="width: 350px"
            >
              <el-option
                v-for="(item, index) in groupList"
                :key="index"
                :value="item.id"
                :label="item.labelName"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择性别">
            <el-radio-group v-model="form.patienceSex">
              <el-radio :label="1">男</el-radio>
              <el-radio :label="2">女</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <el-form-item label="上传人员" v-if="radio == 2">
          <el-button @click="importPat(2)" type="primary">导入患者</el-button>
        </el-form-item>
      </el-form>
      <div v-if="radio == 1" class="button-group-center">
        <el-button type="primary" @click="getPatience(1)">搜索</el-button>
        <el-button @click="reset(1)">重置</el-button>
      </div>
    </div>
    <el-divider></el-divider>
    <div class="con-item">
      <div class="con-item-title">人员列表</div>
      <div class="table-list-con">
        <div class="blank-con" v-if="isInit">
          <img src="~@/assets/img/followUp/blank.png" />
          <div class="blank-tip">
            您可以选择条件，进行随访人员的筛选，也可以进行人员导入，进行随访人员的筛选
          </div>
        </div>
        <div v-else>
          <div>
            <!-- <div style="display: flex">
              <div v-if="!allFlag" class="total-con">
                已选中患者数：{{ dataListSelections.length }}人
              </div>
              <div v-else class="total-con">
                已选中患者数：{{ totalPage }}人
              </div>
              <el-button
                style="margin-left: 20px; height: 40px"
                @click="selectAll()"
                type="primary"
                >全选</el-button
              >
            </div> -->

            <el-table
              :data="list"
              ref="list1"
              v-loading="dataListLoading"
              @selection-change="selectionChangeHandle"
              style="width: 100%"
            >
              <template slot="empty">
                <div class="blank-con" style="padding-top: 17px">
                  <img
                    src="~@/assets/img/followUp/empty.png"
                    style="width: 100px; height: 78px"
                  />
                  <div class="blank-tip">
                    没有符合条件的人员，您可以重新选择条件，进行随访人员的筛选，也可以进行人员导入
                  </div>
                </div>
              </template>
              <!-- <el-table-column
                v-if="!allFlag"
                type="selection"
                width="50"
              ></el-table-column> -->
              <el-table-column label="姓名">
                <template slot-scope="scope">
                  <el-button type="text" @click="handleInfo(scope.row)">{{
                    scope.row.patience_name
                  }}</el-button>
                </template>
              </el-table-column>
              <el-table-column
                prop="patience_link_tel"
                label="联系方式"
              ></el-table-column>
              <el-table-column label="性别">
                <template slot-scope="scope">{{
                  scope.row.patience_sex == 1 ? "男" : "女"
                }}</template>
              </el-table-column>
              <el-table-column
                prop="patience_id_card"
                label="身份证号"
                width="200"
              ></el-table-column>
              <el-table-column
                prop="resource_type_name"
                label="来源"
              ></el-table-column>
              <el-table-column
                prop="create_time"
                label="创建时间"
                sortable
              ></el-table-column>
              <el-table-column
                prop="follow_up_count"
                label="随访次数"
              ></el-table-column>
              <el-table-column
                label="方案数量"
                prop="program_info_count"
                sortable
              ></el-table-column>
              <el-table-column
                label="最近访视时间"
                prop="execution_time"
                sortable
              ></el-table-column>
            </el-table>
            <el-pagination
              v-if="list.length"
              @size-change="sizeChangeHandle"
              @current-change="currentChangeHandle"
              :current-page="pageNo"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="pageSize"
              :total="totalPage"
              layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="button-group-center" style="padding-top: 64px" v-if="!isInit">
      <el-button
        type="primary"
        @click="submit"
        v-points
        >发送</el-button
      >
      <!-- <el-button v-else type="primary" @click="submit" v-points>发送</el-button> -->
    </div>
    <choose-question
      v-show="qsDialog"
      ref="chooseQs"
      @chooseQs="getOther($event)"
      :type="form.initType"
    ></choose-question>
    <!-- 导入 -->
    <importFail
      v-if="importFailVisible"
      ref="importFail"
      :showData="showData"
    ></importFail>
    <el-dialog
      title="导入数据"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="800px"
    >
      <el-form label-width="80px">
        <div class="lineStyle">操作步骤</div>
        <div class="lineStyle">
          1.下载《批量导入模板》
          <a :href="downUrl">下载</a>
        </div>
        <div class="lineStyle">
          2.打开下载表，将对应字段信息输入或粘贴进本表。为保障粘贴信息被有效导入，请使用纯文本或数字，请勿随意增减项目
        </div>
        <div class="lineStyle">
          3.信息输入完毕，点击下方“导入”按钮，选择excel文档完成导入
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <div style="display: flex; flex-direction: row-reverse">
          <el-button style="margin-left: 20px" @click="dialogVisible = false"
            >取消</el-button
          >
          <el-upload
            class="upload-demo"
            :action="url"
            :show-file-list="false"
            :on-success="importSuccess"
            :headers="importHeader"
          >
            <el-button type="primary">导入</el-button>
          </el-upload>
        </div>
      </span>
    </el-dialog>
    <!-- 导入/ -->
  </div>
</template>
<script>
import chooseQuestion from "../zykgl/components/chooseQuestion.vue";
import moment from "moment";
import importFail from "@/components/importFail";
import axios from "axios";
import qs from "qs";
export default {
  name: "followAdd",

  components: {
    chooseQuestion,
    importFail,
  },

  data() {
    return {
      radio: 1,
      showData: "",
      importFailVisible: false,
      dialogVisible: false,
      importHeader: {
        token: localStorage.getItem("token"),
      },
      url: "", //导入链接
      downUrl: "", // 导入下载链接
      activeName: "first",
      dataRules: {
        followUpName: [
          { required: true, message: "随访名称不能为空", trigger: "blur" },
        ],
        followUpType: [
          { required: true, message: "请选择内容类型", trigger: "change" },
        ],
        // workEnertyType: [{ required: true, message: "请选择宣教类型", trigger: "change" }],
        followUpItem: [
          { required: true, message: "内容不能为空", trigger: "blur" },
        ],
        noticeAuther: [
          { required: true, message: "作者不能为空", trigger: "blur" },
        ],
      },
      dataRules2: {
        followUpName: [
          { required: true, message: "随访名称不能为空", trigger: "blur" },
        ],
        followUpType: [
          { required: true, message: "请选择内容类型", trigger: "change" },
        ],
        // workEnertyType: [{ required: true, message: "请选择宣教类型", trigger: "change" }],
        followUpItem: [
          { required: true, message: "内容不能为空", trigger: "blur" },
        ],
        noticeAuther: [
          { required: true, message: "作者不能为空", trigger: "blur" },
        ],
      },
      dataListLoading: false,
      dataListLoading2: false,
      form: {
        addTime: ["", ""],
        followUpName: "",
        followUpType: "",
        completionTime: "",
        followUpItem: [],
        age1: "",
        age2: "",
        groupingId: "",
        patienceSex: "",
        labelId: "",
        initType: "",
      },
      form2: {
        addTime: ["", ""],

        followUpName: "",
        followUpType: "",
        completionTime: "",
        followUpItem: [],
        age1: "",
        age2: "",
        groupingId: "",
        patienceSex: "",
        labelId: "",
        initType: "",
      },
      allFlag: false,
      qsDialog: false,
      qsDialog2: false,
      tagList: [],
      groupList: [],
      list: [],
      list2: [],
      pageSize: 10,
      pageNo: 1,
      selectAllCount: 0,
      dataListLoading: false, //列表loading
      dataListSelections: [], //选中的列
      totalPage: 0, //总页数
      pageSize2: 10,
      pageNo2: 1,
      dataListLoading2: false, //列表loading
      dataListSelections2: [], //选中的列
      totalPage2: 0, //总页数
      isInit: true, //初始化样式
      tableData: [], //导入分页当前获取的数据
      fileUrl: "",
      imgUrl: this.$global.imgUrl,
    };
  },

  watch: {
    "form.followUpType"(v) {
      this.form.initType = v == 1 ? 5 : 4;
      console.log(this.form.initType);
    },
    "form2.followUpType"(v) {
      this.form2.initType = v == 1 ? 5 : 4;
      console.log(this.form2.initType);
    },
  },

  mounted() {
    this.downUrl = this.$global.dominUrl + "/excel/患者导入.xlsx";
    // this.url=this.$http.adornUrl('/teamsPatience/importTeamsPatiencesByNameAndPhone?type=')
    this.getTag();
    this.getGroup();
    // this.getPatience(1);
    // this.getPatience(2)a;
  },

  methods: {
    importPat(num) {
      let teamsId = "";
      let enertyId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      this.dialogVisible = true;
      this.url = this.$http.adornUrl(
        `/teamsPatience/importTeamsPatiencesByNameAndPhone?&teamsId=${teamsId}`
      );
    },
    importSuccess(response, file, fileList) {
      // console.log(file.raw,fileList);
      if (response.status) {
        this.$message({
          message: "操作成功",
          type: "success",
          duration: 1500,
          onClose: () => {
            this.isInit = false;
            this.dialogVisible = false;
            this.dataListSelections2.push(response.data[0]);
            this.tableData = response.data;

            this.pageList();
            let instance = axios.create({
              timeout: 2000,
            });
            let url = this.$global.baseURL + "/aliyun/oss/upload";
            const formData = new FormData();
            formData.append("file", file.raw);
            instance
              .post(url, formData)
              .then(({ data }) => {
                if (data.result.code == '200') {
                  this.fileUrl = data.result.filePath;
                }
                // this.$message({
                //   message: "文件上传成功",
                //   type: "success",
                //   duration: 1500,
                //   onClose: () => {

                //   },
                // });
              })
              .catch(() => {
                this.loading = false;
                failure("服务器出了点小差");
              });
            console.log(this.dataListSelections2);
            // this.getList()
            // this.getSchoolList2()
          },
        });
      } else {
        if (response.data && response.data.length != 0) {
          this.showData = response.data;
          this.importFailVisible = true;
          this.$nextTick(() => {
            this.$refs.importFail.init();
          });
        } else {
          this.$message.error(response.msg + ",请重试");
          this.dialogVisible = false;
        }
      }
    },
    // 分页
    // 具体分页操作
    pageList() {
      this.list = this.tableData.filter(
        (item, index) =>
          index < this.pageNo * this.pageSize &&
          index >= this.pageSize * (this.pageNo - 1)
      );
      this.totalPage = this.tableData.length;
    },
    selectAll(num) {
      this.allFlag = !this.allFlag;
      this.$refs.list1.clearSelection();
      // this.$refs.list1.toggleAllSelection();
      // if (num === 1) {
      //   this.$refs.list1.toggleAllSelection();
      // } else {
      //   this.$refs.list2.toggleAllSelection();
      // }
    },
    reset(num) {
      if (num == 1) {
        this.form.addTime = ["", ""];
        this.form.age1 = "";
        this.form.age2 = "";
        this.form.groupingId = "";
        this.form.patienceSex = "";
        this.form.labelId = "";
      } else {
        this.form2.addTime = ["", ""];
        this.form2.age1 = "";
        this.form2.age2 = "";
        this.form2.groupingId = "";
        this.form2.patienceSex = "";
        this.form2.labelId = "";
      }
    },
    async submit2() {
      let _this = this;
      let enertyId = "";
      let form = {};
      if (!this.form2.followUpType) {
        _this.$message.error("请选择内容分类");
        return;
      }
      if (!this.form2.followUpItem.name) {
        _this.$message.error("请选择内容");
        return;
      }
      if (!this.form2.followUpName) {
        _this.$message.error("请输入随访名称");
        return;
      }
      if (localStorage.currentTeam) {
        form.teamId = JSON.parse(localStorage.currentTeam).id;
        form.enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      if (_this.form2.followUpType == 1) {
        form.contentType = _this.form2.followUpItem.contentType;
        form.contentId = _this.form2.followUpItem.id;
        form.contentTypeSec = _this.form.followUpItem.contentTypeSec;
      } else {
        form.contentType = _this.form2.followUpItem.workType;
        form.contentTypeSec = _this.form2.followUpItem.workTypeSec;
        form.contentTypeThree = _this.form2.followUpItem.workTypeThree;
        form.contentTypeFour = _this.form2.followUpItem.workTypeFour;
        form.contentTypeFive = _this.form2.followUpItem.workTypeFive;

        // _this.form2.contentType = _this.form2.followUpItem.workType;
        form.contentId = _this.form2.followUpItem.id;
        form.workType = _this.form2.followUpItem.workType;
        form.workId = _this.form2.followUpItem.id;
      }
      if (!this.dataListSelections2.length) {
        _this.$message.error("请选择发送人员");
        return;
      } else {
        let arr = Array.from(
          new Set(this.dataListSelections2.map((v) => v.patience_id))
        );
        form.followUpId = arr.join(",");
        //  let ids = this.dataListSelections.map(v=>v.id).join(',')
        console.log(arr, "form2");
      }
      form.followUpTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      form.followUpName = this.form2.followUpName;
      form.followUpType = this.form2.followUpType;
      form.completionTime = this.form2.completionTime;

      form.hospitalId = JSON.parse(localStorage.getItem("userInfo")).deptPid;
      form.deptId = JSON.parse(localStorage.getItem("userInfo")).deptId;
      console.log(form, "form2");
      // return;
      _this
        .$http({
          url: _this.$http.adornUrl("/followUp/add"),
          method: "post",
          data: qs.stringify(form),
        })
        .then(({ data }) => {
          if (data.status) {
            _this.$message({
              message: data.msg,
              type: "success",
              duration: 1500,
              onClose: () => {
                _this.$router.back();
                // _this.visible = false;
                // _this.flag = true;
                // _this.sendConfirmDialog = false;
                // _this.$emit("refreshDataList",1);
              },
            });
          } else {
            _this.$message.error(data.msg);
            // _this.flag = true;
          }
        });
    },
    del(row) {
      console.log(this.form.followUpItem);
      console.log(row);
      this.form.followUpItem = this.form.followUpItem.filter((v) => v != row);
    },
    async submit() {
      let _this = this;
      let enertyId = "";
      let teamsId = ""
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      let form = {};
      if (!this.form.followUpName) {
        _this.$message.error("请输入随访名称");
        return;
      }
      if (!this.form.followUpType) {
        _this.$message.error("请选择内容类型");
        return;
      }
      if (!this.form.followUpItem.length) {
        _this.$message.error("请选择内容");
        return;
      }
      if (localStorage.currentTeam) {
        form.teamId = JSON.parse(localStorage.currentTeam).id;
        form.enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      if (_this.form.followUpType == 1) {
        // form.contentType = _this.form.followUpItem.contentType;
        form.contentIds = _this.form.followUpItem.map((v) => v.id).join(",");
        form.contentNames = _this.form.followUpItem
          .map((v) => v.name)
          .join(",");
        console.log(form.contentIds, "form.contentId", form.contentNames);
        // form.contentTypeSec = _this.form.followUpItem.contentTypeSec;
      } else {
        form.contentIds = _this.form.followUpItem.map((v) => v.id).join(",");
        form.contentNames = _this.form.followUpItem
          .map((v) => v.name)
          .join(",");
      }
      // if (this.allFlag) {
      //   form.followUpId = "";
      // } else {
      //   if (!this.dataListSelections.length) {
      //     _this.$message.error("请选择发送人员");
      //     return;
      //   } else {
      //     form.followUpId = _this.dataListSelections
      //       .map((v) => v.patience_id)
      //       .join(",");

      //     //  let ids = this.dataListSelections.map(v=>v.id).join(',')
      //   }
      // }
      if (_this.radio == 1) {
        form.teamsId = teamsId;
        form.patienceSex = _this.form.patienceSex;
        form.patienceAgeMin = _this.form.age1;
        form.patienceAgeMax = _this.form.age2;
        form.joinStartTime = _this.form.addTime&&_this.form.addTime.length?_this.form.addTime[0]:"";
        form.joinEndTime = _this.form.addTime&&_this.form.addTime.length?_this.form.addTime[1]:"";
        form.labelId = _this.form.labelId;
        form.groupingId = _this.form.groupingId;
      } else {
        form.url = _this.fileUrl;
      }

      form.type = _this.radio;
      form.followUpTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      form.followUpName = this.form.followUpName;
      form.completionTime = this.form.completionTime;
      form.followUpType = this.form.followUpType;
      form.hospitalId = JSON.parse(localStorage.getItem("userInfo")).deptPid;
      form.deptId = JSON.parse(localStorage.getItem("userInfo")).deptId;
      console.log(form, "form");
      // return;
      _this
        .$httpAes({
          url: _this.$http.adornUrl("/followUp/insertFollowUps"),
          method: "post",
          data: form,
        })
        .then(({ data }) => {
          if (data.status) {
            _this.$message({
              message: data.msg,
              type: "success",
              duration: 1500,
              onClose: () => {
                _this.$router.back();
                // _this.visible = false;
                // _this.flag = true;
                // _this.sendConfirmDialog = false;
                // _this.$emit("refreshDataList",1);
              },
            });
          } else {
            _this.$message.error(data.msg);
            // _this.flag = true;
          }
        });
    },
    addMain() {
      if (!this.form.followUpType) {
        this.$message.error("请先选择分类！");
        return;
      }
      this.qsDialog = true;
      sessionStorage.setItem("type", this.form.followUpType == 1 ? 5 : 4);

      //  let ref = `chooseQs${index}`
      this.$nextTick(() => {
        // this.$refs.ref.init();
        this.$refs.chooseQs.init();
        if (this.form.followUpItem.length) {
          this.$refs.chooseQs.edit(this.form.followUpItem);
        }
      });
    },

    addMain2() {
      if (!this.form2.followUpType) {
        this.$message.error("请先选择分类！");
        return;
      }
      this.qsDialog2 = true;
      sessionStorage.setItem("type", this.form2.followUpType == 1 ? 5 : 4);

      //  let ref = `chooseQs${index}`
      this.$nextTick(() => {
        // this.$refs.ref.init();
        this.$refs.chooseQs2.init();
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageNo = 1;
      if (this.radio == 1) {
        this.getPatience(1);
      } else {
        this.pageList();
      }
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      if (this.radio == 1) {
        this.getPatience(1);
      } else {
        this.pageList();
      }
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 每页数
    sizeChangeHandle2(val) {
      this.pageSize2 = val;
      this.pageNo2 = 1;
      this.getPatience(2);
    },
    // 当前页
    currentChangeHandle2(val) {
      this.pageNo2 = val;
      this.getPatience(2);
    },
    // 多选
    selectionChangeHandle2(val) {
      this.dataListSelections2 = val;
    },
    async getPatience(type) {
      let that = this;
      that.isInit = false;
      const user = JSON.parse(localStorage.getItem("userInfo"));
      let teamsId = "";
      let enertyId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      let params = {};
      if (type == 1) {
        params = {
          intParam1: that.pageSize,
          intParam2: that.pageNo,
          // mbglResourceType: that.sourceValue,
          // mbglReasonType: that.reasonValue,
          // fieldQuery: that.selectString,
          // fieldName: that.stringValue,
          stringParam11: "",
          stringParam1: teamsId,
          stringParam4: that.form.patienceSex,
          stringParam6: that.form.age1,
          stringParam7: that.form.age2,
          stringParam13: that.form.addTime&& that.form.addTime.length?that.form.addTime[0]:"",
          stringParam14: that.form.addTime&& that.form.addTime.length?that.form.addTime[1]:"",
          labelId: that.form.labelId,
          stringParam10: that.form.groupingId,
        };
      } else {
        console.log(that.form2.age1, that.form2.age2);
        params = {
          intParam1: that.pageSize2,
          intParam2: that.pageNo2,
          // mbglResourceType: that.sourceValue,
          // mbglReasonType: that.reasonValue,
          // fieldQuery: that.selectString,
          // fieldName: that.stringValue,
          stringParam11: "",
          stringParam1: teamsId,
          stringParam4: that.form2.patienceSex,
          stringParam6: that.form2.age1,
          stringParam7: that.form2.age2,
          stringParam13: that.form.addTime&& that.form.addTime.length?that.form.addTime[0]:"",
          stringParam14: that.form.addTime&& that.form.addTime.length?that.form.addTime[1]:"",
          labelId: that.form2.labelId,
          stringParam10: that.form2.groupingId,
        };
      }
      const { data: res } = await this.$httpAes({
        url: this.$http.adornUrl("/teamsPatience/selectTeamsPatience"),
        method: "post",
        data: params,
      });
      if (res.status) {
        if (type == 1) {
          this.totalPage = res.totalCount;
          this.list = res.data;
        } else {
          this.totalPage2 = res.totalCount;
          this.list2 = res.data;
        }
      }
    },
    async getTag() {
      const user = JSON.parse(localStorage.getItem("userInfo"));
      let teamsId = "";
      let enertyId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      let params = {
        // hospitalId: user.deptPid,
        	stringParam11: teamsId,
		intParam1: 999,
		intParam2: 1,
		intParam3: 1,
		stringParam8: user.id
      };
      const { data: res } = await this.$httpAes({

        url: this.$http.adornUrl("/mbglLabel/list"),
        method: "post",
        data: params,
      });
      if (res.status) {
        this.tagList = res.data;
      }
    },
    async getGroup() {
      const user = JSON.parse(localStorage.getItem("userInfo"));
      let teamsId = "";
      let enertyId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      let params = {
        // hospitalId: user.deptPid,
      
		stringParam11: teamsId,
		intParam1: 999,
		intParam2: 1,
		intParam3: 2,
		stringParam8: user.id
      };
      const { data: res } = await this.$httpAes({

        url: this.$http.adornUrl("/mbglLabel/list"),
        method: "post",
        data: params,
      });
      if (res.status) {
        this.groupList = res.data;
      }
    },
    getOther(val) {
      console.log(val, "val gteOther");
      this.form.followUpItem = val;
    },
    getOther2(val) {
      console.log(val, "val gteOther");
      this.form2.followUpItem = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.followAdd {
  .con-item {
    .con-item-title {
      font-size: 16px;
      font-weight: bold;
      padding-bottom: 24px;
    }
  }
  .inline-form {
    display: flex;
    .el-form-item {
      flex: 1;
    }
  }
  .el-button {
    img {
      width: 14px;
      height: 14px;
    }
  }
  .form-row {
    display: flex;
    .el-form-item {
      flex: 1;
    }
    .form-row-flex {
      display: flex;
      align-items: center;
    }
    .age-range-line {
      display: inline-block;
      width: 20px;
      height: 1px;
      background: #979797;
      margin: 0 10px;
    }
  }
  .button-group-center {
    display: flex;
    justify-content: center;
    padding-top: 40px;
  }
  .blank-con {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6px 0 66px;
    img {
      width: 223px;
      height: 122px;
    }
    .blank-tip {
      color: #999999;
      padding-top: 20px;
    }
  }
  .total-con {
    display: inline-block;
    height: 36px;
    background: #e7f5ff;
    border-radius: 2px;
    color: #0099ff;
    padding: 8px 10px;
    margin-bottom: 32px;
  }
  .line {
    width: 100%;
    height: 1px;
    background: #999;
  }
  .heng {
    width: 100%;
    height: 1px;
    background: #eee;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
</style>
